import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse,
  useRouteError,
} from "react-router"
import type { LinksFunction } from "react-router"

import "./app.css"

export const links: LinksFunction = () => [
  { rel: "preconnect", href: "https://fonts.googleapis.com" },
  {
    rel: "preconnect",
    href: "https://fonts.gstatic.com",
    crossOrigin: "anonymous",
  },
  {
    rel: "stylesheet",
    href: "https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap",
  },
]

export function Layout({ children }: { children: React.ReactNode }) {
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body>
        {children}
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  )
}

export default function App() {
  return <Outlet />
}

export function ErrorBoundary() {
  const error = useRouteError()

  if (isRouteErrorResponse(error)) {
    return (
      <div className="flex flex-col bg-background p-4">
        <h1 className="text-4xl font-bold">
          {error.status} {error.statusText}
        </h1>
        <p className="mt-4">{error.data}</p>
      </div>
    )
  }

  if (error instanceof Error) {
    return (
      <div className="flex flex-col bg-background p-4">
        <h1 className="text-4xl font-bold">Error</h1>
        <p className="mt-4">{error.message}</p>
        <pre className="mt-4 whitespace-pre-wrap">{error.stack}</pre>
      </div>
    )
  }

  return (
    <div className="flex flex-col bg-background p-4">
      <h1 className="text-4xl font-bold">Unknown Error</h1>
    </div>
  )
}
